import api from "../api";
import apiChat from "../apiChat";
export const getMessagesClient = async (instanceId, phone) => {
  return await apiChat.get(`/message/client/${instanceId}/${phone}`);
};

export const sendMessage = async (instanceId, phone, message) => {
  return await apiChat.post(`/message/instance/${instanceId}/${phone}/send`, {
    message: message,
  });
};

export const indexContactChatService = async (filter) => {
  return await api.get(`/api/v1/chat/contact`, {
    params: filter,
  });
};

export const indexMessageChatService = async (clientPhoneId, instanceId) => {
  return await api.get(`/api/v1/chat/message`, {
    params: {
      clientPhoneId: clientPhoneId,
      instanceId: instanceId,
    },
  });
};

export const readReceiptMessage = async (clientPhoneNumberId, instanceId) => {
  return await api.put(
    `/api/v1/chat/readReceipt/${clientPhoneNumberId}`,
    { instanceId }
  );
};

export const getPhoneClientService = async (phone) => {
  return await api.get(`/api/v1/chat/phone`, {
    params: {
      phone: phone,
    },
  });
};

export const updateUser = async (data, clientId) => {
  return await api.put(`/api/v1/chat/user/${clientId}`, data);
};

export const updateUserIdChat = async (clientId, data) => {
  return await api.put(`/api/v1/chat/user/setUserIdChat/${clientId}`, data);
};

export const serviceLinkGroup = async (clientId, data) => {
  return await api.post(`/api/v1/chat/group/${clientId}`, data);
};

export const registerObservation = async (clientId, data) => {
  return await api.post(`/api/v1/chat/observation`, data, {
    params: {
      clientId: clientId,
    },
  });
};

export const deleteObservation = async (obsId, data) => {
  return await api.delete(`/api/v1/chat/observation/status/${obsId}`, { data });
};

export const countContacts = async (params = {}) => {
  return await api.get(`/api/v1/chat/contacts`, { params });
}

export const listAllTransferable = async () =>{
  return await api.get(`/api/v1/chat/accessUser`);
}

export const indexFilterMessageChatService = async (clientPhoneId, message) => {
  return await api.get(`/api/v1/chat/message`, {
    params: {
      clientPhoneId: clientPhoneId,
      message: message,
    },
  });
};

export const getRegisterShortcutMessages = async () => {
  return await api.get('/api/v1/shortcut-messages');
}
