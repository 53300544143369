import api from "../api"

export const getUser = async () => {
  return await api.get('/api/v1/user/informations');
};

export const updateUser = async (data) => {
  return await api.put(`/api/v1/user/informations`, data);
};

export const generateCredentialService = async (type) => {
  return await api.post(`/api/v1/user/generate-credential/${type}`);
};

export const insertWebHook = async (data) => {
  return await api.post(`/api/v1/user/webhook`, data);
};

export const getWebHook = async (data) => {
  return await api.get(`/api/v1/user/webhook`, data);
};

export const setStatusServiceWebHook = async (id, active) => {
  return await api.put(`/api/v1/user/webhook/${id}`, active);
};