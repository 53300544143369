// -- React and related libs
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
import Typography from "../../pages/typography/Typography";
import Notifications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
import { Chat } from "../../pages/chat/index";
import { ButtonToggle, Image } from "./ButtonSyle";
import Menu from "./assets/menu.png"
import CollapseIcon from "./assets/collapseIcon.png"

// -- Component Styles
import s from "./Layout.module.scss";
import Instance from "../../pages/instance/Instance";
import Messages from "../../pages/messages/Messages";
import User from "../../pages/user/User";
import Schedule from "../../pages/client-schedule/Schedule";
import Chatbot from "../../pages/chatbot/Chatbot";
import UpdatePasswordView from "../../pages/register/UpdatePasswordView";
import CampaignList from "../../pages/campaign-list/CampaignList";
import CampaignInfo from "../../pages/campaign-list/CampaignInfo";
import ChatbotQuestionView from "../../pages/chatbot/ChatbotQuestionView";
import Analyitcs from "../../pages/analytics/Analytics";
import CampaignTemplate from "../../pages/campaign-template/CampaignTemplate";
import BlackList from "../../pages/blackList-numbers/BlackList";
import Profile from "../../pages/profile/Profile";
import SimplifiedRegister from "../../pages/simplified-register/SimplifiedRegister";
import Finance from "../../pages/finance/Finance";
import Calendar from "../../pages/calendar/Calendar";
import Department from "pages/department/Department";
import ChatConfig from "../../pages/chat-config/ChatConfig";
import Bills from "../../pages/Bills/Bills";
import Report from "../../pages/Bills/Reports/Report";
import Onboarding from "pages/Onboarding/Onboarding";
import ShortcutMessages from "pages/shortcut-messages/ShortcutMessages";
import GroupContacts from "pages/group-contacts/GroupContacts";



const Layout = (props) => {
  const [wrapClass, setWrapClass] = useState(s.wrap);
  const [buttonImage, setButtonImage] = useState(CollapseIcon);
  const [buttonClass, setButtonClass] = useState("classColapsed");
  const location = useLocation();
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const toggleWrapClass = () => {
    if (wrapClass === s.wrap) {
      setWrapClass(s.customWrap);
      setButtonImage(Menu);
      setButtonClass("classMenu");
    } else {
      setWrapClass(s.wrap);
      setButtonImage(CollapseIcon);
      setButtonClass("classColapsed");
    }
  };

  useEffect(() => {
    if (location.pathname === "/admin/onboarding") {
      setWrapClass(s.customWrap);
      setButtonImage(Menu);
      setButtonClass("classMenu");

    } else {
      setWrapClass(s.wrap);
      setButtonImage(CollapseIcon);
      setButtonClass("classColapsed");
    }
    if (user?.requiredOnboarding > 0 && user?.hasAddress == true && user?.plan >= 0) {
      history.push("/admin/onboarding");
      return;
    }

    if (user?.isUpdatePassword > 0) {
      history.push("/admin/update-password");
      return;
    }
  }, [location]);

  return (
    <div className={s.root}>
      <ButtonToggle className={buttonClass} onClick={toggleWrapClass}><Image src={buttonImage}></Image></ButtonToggle>
      <div className={wrapClass}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to="admin/dashboard" />}
            />
            <Route
              path="/admin/dashboard"
              exact
              render={() => <Dashboard customGrow={wrapClass === s.customWrap} />}
            />
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/analytics" exact component={Analyitcs} />
            <Route path="/admin/bills" exact component={Bills} />
            <Route
              path="/admin/instance"
              exact
              render={() => <Instance customGrow={wrapClass === s.customWrap} />}
            />
            <Route path="/admin/messages" exact component={Messages} />
            <Route path="/admin/campaign-list" exact component={CampaignList} />
            <Route path="/admin/client-schedule" exact component={Schedule} />
            <Route path="/admin/group-contacts" exact component={GroupContacts} />
            <Route path="/admin/campaign-template" exact component={CampaignTemplate} />
            <Route path="/admin/campaignInfo/:id" exact component={CampaignInfo} />
            <Route path="/admin/chatbot" exact component={Chatbot} />
            <Route path="/admin/chat" exact component={Chat} />
            <Route path="/admin/blacklist-numbers" exact component={BlackList} />
            <Route path="/admin/profile" exact component={Profile} />
            <Route path="/admin/simplified-register" exact component={SimplifiedRegister} />
            <Route path="/admin/chatbot/question/:id" exact component={ChatbotQuestionView} />
            <Route path="/admin/finance" exact component={Finance} />
            <Route path="/admin/calendar" exact component={Calendar} />
            <Route path="/admin/department" exact component={Department} />
            <Route path="/admin/chat-config" exact component={ChatConfig} />
            <Route path="/admin/bills/report" exact component={Report} />
            <Route
              path="/admin/update-password"
              exact
              component={UpdatePasswordView}
            />
            {/* <Route path="/admin/chat" exact component={Chat}/> */}
            <Route path="/admin/user" exact component={User} />
            <Route path="/admin/typography" exact component={Typography} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/onboarding" exact component={Onboarding} />
            <Route path="/admin/shortcut-messages" exact component={ShortcutMessages} />
            <Route
              path="/admin/notifications"
              exact
              component={Notifications}
            />
            {/* <Route
              path="/admin/ui-elements"
              exact
              render={() => <Redirect to={"/template/ui-elements/charts"} />}
            />
            <Route path="/admin/ui-elements/charts" exact component={Charts} />
            <Route path="/admin/ui-elements/icons" exact component={Icons} />
            <Route path="/admin/ui-elements/maps" exact component={Maps} /> */}
            <Route path="*" exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
