import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Tooltip } from "reactstrap";
import { getGroupsContacts } from "services/Client-Schedule";
import Swal from "sweetalert2";

const GroupContacts = () => {
    const history = useHistory();
    const [result, setResult] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState({});
    const [currentTooltipText, setCurrentTooltipText] = useState('');
    const titleRefs = useRef({});

    const handleList = async () => {
        Swal.fire({
            title: "Carregando....",
            text: "Por favor aguarde!",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            await getGroupsContacts().then((res) => {
                if (!res.data.status) return;
                setResult(res?.data?.resultSet ?? []);
                Swal.close();
            });
        } catch (err) {
            const code = !err.response ? "00000" : err.response.data.code;
            const message = !err.response
                ? "Ocorreu um erro inesperado"
                : err.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleTitleMouseEnter = (text, id) => {
        setCurrentTooltipText(text);
        setTooltipOpen((prevState) => ({ ...prevState, [id]: true }));
    };

    const handleTitleMouseLeave = (id) => {
        setTooltipOpen((prevState) => ({ ...prevState, [id]: false }));
    };

    useEffect(() => {
        handleList();
    }, []);

    return (
        <>
            <Row className="gutter">
                <Col>
                    <Card className="w-100 border-0 shadow">
                        <CardHeader className="bg-white">
                            <h4 className="mb-0">Grupos</h4>
                        </CardHeader>
                        <CardBody className="w-100">
                            <Row className="d-flex align-items-stretch px-2">
                                {result.length > 0 ? (
                                    result.map((register) => (
                                        <Col
                                            key={register.id}
                                            xl="3"
                                            lg="4"
                                            md="6"
                                            className="mb-3 px-2"
                                        >
                                            <Card className="text-center h-100 shadow-sm rounded-lg border-primary">
                                                <CardHeader className="bg-light border-primary rounded-top">
                                                    <h6
                                                        ref={(el) => (titleRefs.current[register.id] = el)}
                                                        className="mb-0 text-dark font-weight-bold text-truncate"
                                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                        onMouseEnter={() => handleTitleMouseEnter(register.name, register.id)}
                                                        onMouseLeave={() => handleTitleMouseLeave(register.id)}
                                                    >
                                                        <i className="fa-solid fa-people-group mr-2"></i>
                                                        {register.name}
                                                    </h6>
                                                    {titleRefs.current[register.id] && (
                                                        <Tooltip
                                                            isOpen={tooltipOpen[register.id]}
                                                            target={titleRefs.current[register.id]}
                                                            toggle={() => setTooltipOpen((prev) => ({ ...prev, [register.id]: !prev[register.id] }))}
                                                            placement="top"
                                                        >
                                                            {currentTooltipText}
                                                        </Tooltip>
                                                    )}
                                                </CardHeader>
                                                <CardBody className="h-100 d-flex flex-column align-items-center justify-content-center bg-light rounded-bottom">
                                                    <h2 className="text-primary mb-0">{register.contactCount}</h2>
                                                    <h6 className="text-secondary mb-0">contatos</h6>
                                                </CardBody>
                                                <CardFooter className="bg-light border-0 text-center rounded-bottom pt-0">
                                                    <Button
                                                        color="primary"
                                                        className="w-100"
                                                        onClick={() => history.push(`/admin/client-schedule?group=${register.id}`)}
                                                    >
                                                        <i className="fa-solid fa-address-card mr-2"></i>
                                                        Ver Contatos
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <Col>
                                        <div className="alert alert-info w-100">
                                            <h4 className="text-info m-0">
                                                Sem grupos no momento...
                                            </h4>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default GroupContacts;
