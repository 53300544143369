import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
  Alert,
} from "reactstrap";
import hasToken from "../../services/authService";
import { createUser } from "../../services/Register/User.js";
import Swal from "sweetalert2";
import Logo from "../../assets/logo.png";
import registerImage from "../../assets/register-image.png";
import { phoneMask, maskDocument, cepMask } from "../../utils/mask.js";
import { brasilApi } from "services/Utils/BrasilApiService";

const Register = (props) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    document: '',
    phone: '',
    recommendation: '',
    othersRecommendation: '',
    zipCode: '',
    address: '',
    numberAddress: '',
    district: '',
    city: '',
    complement: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeCred = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const doRegister = async (event) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    event.preventDefault();
    setIsSubmitting(true);

    console.log(state);

    try {
      const res = await createUser(state);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Registro realizado com sucesso',
      }).then(() => {
        setIsSubmitting(false);
        props.history.push('/login');
      });
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGetZipCode = (cep) => {
    setState({
      ...state,
      zipCode: cepMask(cep),
    });

    if (cep.replace(/[^0-9]/g, "").length >= 8) {
      Swal.fire({
        title: "Carregando...",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      brasilApi(cep).then(({ data }) => {
        Swal.close();
        setState((prevState) => ({
          ...prevState,
          zipCode: cepMask(data.cep),
          address: data.street,
          district: data.neighborhood,
          city: data.city,
          state: data.state,
        }));
      })
        .catch((err) => {
          setState((prevState) => ({
            ...prevState,
            zipCode: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Não foi possível identificar o CEP informado`
          });
        });
    }
  };

  const { from } = props.location.state || { from: { pathname: '/admin' } };

  if (hasToken(JSON.parse(localStorage.getItem('authenticated_izichat')))) {
    return <Redirect to={from} />;
  }

  return (
    <div className="auth-page register">
      <Container className="col-12">
        <Row className="position-absolute logo-row w-100">
          <img src={Logo} alt="Logo" className="logo-block" />
        </Row>
        <Row className="d-flex align-items-start justify-content-center">
          <Col xs={12} lg={6} className="left-column d-flex flex-column align-items-center justify-content-center registerDiv">
            <div className="d-flex align-items-center justify-content-between py-3">
              <p className="auth-header mb-0">Registrar</p>
            </div>

            <form onSubmit={(event) => doRegister(event)}>
              <Row>
                <Col xl='6' className="px-1">
                  <FormGroup className="my-3">
                    <FormText className="formText">Nome <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      id="name"
                      className="input"
                      value={state.name}
                      onChange={(event) => changeCred(event)}
                      type="text"
                      required
                      name="name"
                      placeholder="Informe aqui seu nome"
                    />
                  </FormGroup>
                </Col>
                <Col xl='6' className="px-1">
                  <FormGroup className="my-3">
                    <FormText className="formText">CPF/CNPJ <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      id="document"
                      className="input"
                      value={maskDocument(state.document)}
                      onChange={(event) => changeCred(event)}
                      type="text"
                      required
                      name="document"
                      placeholder="Informe o numero do seu CPF ou CNPJ"
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className="my-3">
                    <FormText className="formText">Celular Whatsapp <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      id="phone"
                      className="input"
                      value={phoneMask(state.phone)}
                      onChange={(event) => changeCred(event)}
                      type="text"
                      required
                      name="phone"
                      placeholder="Informe seu numero Whatsapp"
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText"> CEP <span className="text-danger">*</span></FormText>
                    <Input
                      className="input"
                      placeholder="00000-000"
                      name='zipCode'
                      value={state.zipCode}
                      onChange={(ev) => {
                        handleGetZipCode(ev.target.value);
                      }}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">
                      Endereço <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      name='address'
                      value={state.address}
                      className="input"
                      placeholder="Rua, Avenida, Travessa, etc."
                      onChange={(event) => changeCred(event)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">
                      Número <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      name="numberAddress"
                      value={state.numberAddress}
                      className="input"
                      placeholder="Nº da residência"
                      onChange={(event) => changeCred(event)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">
                      Bairro <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      value={state.district}
                      name="district"
                      className="input"
                      placeholder="Bairro"
                      onChange={(event) => changeCred(event)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">
                      Cidade <span className="text-danger">*</span>
                    </FormText>
                    <Input value={state.city} name='city' className="input" placeholder="Cidade" readOnly required />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">
                      Estado <span className="text-danger">*</span>
                    </FormText>
                    <Input value={state.state} name='state' className="input" placeholder="UF" readOnly required />
                  </FormGroup>
                </Col>
                <Col xl="6" className="px-1">
                  <FormGroup className='my-3'>
                    <FormText className="formText">Complemento</FormText>
                    <Input
                      name="complement"
                      value={state.complement}
                      className="input"
                      placeholder="Ex Casa"
                      onChange={(event) => changeCred(event)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="px-1">
                  <FormGroup className="my-3">
                    <div className="d-flex justify-content-between">
                      <FormText className="formText">Como conheceu o izichat? <span className="text-danger">*</span>
                      </FormText>
                    </div>
                    <select
                      id="recommendation"
                      className="form-control select"
                      value={state.recommendation}
                      onChange={(event) => changeCred(event)}
                      required
                      name="recommendation"
                    >
                      <option value="">Selecione...</option>
                      <option value="INSTAGRAM">INSTAGRAM</option>
                      <option value="GOOGLE">GOOGLE</option>
                      <option value="FACEBOOK">FACEBOOK</option>
                      <option value="SITE">SITE</option>
                      <option value="LEV">LEV</option>
                      <option value="BEVI">BEVI</option>
                      <option value="CONSIGA">CONSIGA</option>
                      <option value="IZIDEV">IZIDEV</option>
                      <option value="AD PROMOTORA">AD PROMOTORA</option>
                      <option value="ICRED">ICRED</option>
                      <option value="GRANDINO BANK">GRANDINO BANK</option>
                      <option value="PRESENÇA BANK">PRESENÇA BANK</option>
                      <option value="FACTA">FACTA</option>
                      <option value="MERCANTIL">MERCANTIL</option>
                      <option value="BANCO PAN">BANCO PAN</option>
                      <option value="others">Outros</option>
                    </select>
                  </FormGroup>
                </Col>
                {state.recommendation === "others" && (
                  <Col md="12" className="px-1">
                    <FormGroup className="my-3">
                      <div className="d-flex justify-content-between">
                        <FormText className="formText">Digite aqui <span className="text-danger">*</span>
                        </FormText>
                      </div>
                      <Input
                        id="othersRecommendation"
                        className="input"
                        value={state.othersRecommendation}
                        onChange={(event) => changeCred(event)}
                        required
                        type="text"
                        name="othersRecommendation"
                        placeholder="Digite aqui como conheceu o izichat."
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col md="12" className="px-1">
                  <FormGroup className="my-3">
                    <FormText className="formText">Email <span className="text-danger">*</span>
                    </FormText>
                    <Input
                      id="email"
                      className="input"
                      value={state.email}
                      onChange={(event) => changeCred(event)}
                      type="email"
                      required
                      name="email"
                      placeholder="Informe aqui seu e-mail"
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="px-1">
                  <FormGroup className="my-3">
                    <div className="d-flex justify-content-between">
                      <FormText className="formText">Senha <span className="text-danger">*</span>
                      </FormText>
                    </div>
                    <Input
                      id="password"
                      className="input"
                      value={state.password}
                      onChange={(event) => changeCred(event)}
                      type="password"
                      required
                      name="password"
                      placeholder="Informar aqui sua senha"
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="px-1">
                  <Alert color="dark" style={{ color: 'black' }} className="col-12 my-3">
                    Campos com (*) são obrigatórios
                  </Alert>
                </Col>
                <Col md='12' className="px-1">
                  <div className="bg-widget d-flex justify-content-center">
                    <Button
                      className="my-3 w-100 rounded-0 border-0 text-light"
                      style={{ backgroundColor: '#009582' }}
                      disabled={isSubmitting}
                    >
                      Cadastrar
                    </Button>
                  </div>
                  <div className="text-center">
                    <Link to="/login">Fazer login</Link>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={registerImage} alt="Error page" className="registerImage" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Register.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Register));