import { useEffect, useState } from "react";
import {
  generateCredentialService,
  getUser,
  updateUser,
  insertWebHook,
  getWebHook,
  setStatusServiceWebHook
} from "../../services/Profile";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Row,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Swal from "sweetalert2";
import s from "./Profile.module.scss";
import { cepMask, phoneMask } from "../../utils/mask";
import { brasilApi } from "../../services/Utils/BrasilApiService";

const Profile = () => {
  const [user, setUser] = useState([]);
  const [webHook, setWebHook] = useState('')
  const [result, setResult] = useState('')
  const [editMode, setEditMode] = useState(false);
  const [tabSelected, setTabSelected] = useState(1);
  const [editedUser, setEditedUser] = useState({
    name: "",
    birthDate: "",
    zipCode: "",
    street: "",
    numberAddress: "",
    district: "",
    city: "",
    state: "",
    complement: "",
  });

  const getUsers = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getUser().then((res) => {
        Swal.close();
        return res;
      });
      setUser(response.data.resultSet);
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const getWebhook = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getWebHook().then((res) => {
        Swal.close();
        return res;
      });

      const webhookData = response.data.resultSet[0] || {};
      setResult(webhookData);
      setWebHook(webhookData.url || '');

    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleEdit = (user) => {
    setEditMode(true);
    setEditedUser(user);
  };

  const handleSave = async () => {
    if (
      !editedUser.name ||
      !editedUser.birthDate ||
      !editedUser.zipCode ||
      !editedUser.street ||
      !editedUser.numberAddress ||
      !editedUser.district ||
      !editedUser.city ||
      !editedUser.state
    ) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Por favor, todos os campos são obrigatórios!",
      });
      return;
    }

    Swal.fire({
      title: "Carregando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const updatedUserInfo = {
      name: editedUser.name,
      birthDate: editedUser.birthDate,
      zipCode: editedUser.zipCode,
      street: editedUser.street,
      numberAddress: editedUser.numberAddress,
      district: editedUser.district,
      city: editedUser.city,
      state: editedUser.state,
      complement: editedUser.complement,
    };

    try {
      const response = await updateUser(updatedUserInfo);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Perfil atualizado com sucesso!",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });

        getUsers();
        setEditMode(false);
        setEditedUser({});
      }
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleSaveWebHook = async () => {
    if (
      !webHook
    ) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Por favor, o campo é obrigatório!",
      });
      return;
    }

    Swal.fire({
      title: "Carregando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        url: webHook
      }

      const response = await insertWebHook(data);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Webhook atualizado com sucesso!",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });

        getWebhook();
      }
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const setHookStatus = async (webHookId, active) => {
    const action = Number(active) == 0 ? "Inativar" : "Ativar";

    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: `Você tem certeza que deseja ${action} este Webhook?`,
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        await setStatusServiceWebHook(webHookId);

        const updatedResult = { ...result, active: active };
        setResult(updatedResult);

        Swal.fire({
          icon: "success",
          title: "Operação realizada com sucesso.",
          confirmButtonText: "Fechar",
          allowOutsideClick: false,
        });
      } catch (err) {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      }
    });
  };

  const handleGetZipCode = (cep) => {
    setEditedUser({
      ...editedUser,
      zipCode: cepMask(cep),
    });

    if (cep.replace(/[^0-9]/g, "").length >= 8) {
      Swal.fire({
        title: "Carregando...",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      brasilApi(cep)
        .then(({ data }) => {
          Swal.close();
          setEditedUser({
            ...editedUser,
            zipCode: cepMask(data.cep),
            street: data.street,
            district: data.neighborhood,
            city: data.city,
            state: data.state,
          });
        })
        .catch((err) => {
          setEditedUser({
            ...editedUser,
            zipCode: "",
            street: "",
            district: "",
            city: "",
            state: "",
          });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Não foi possível identificar o CEP informado`,
          });
        });
    }
  };

  const handleGenerateToken = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja gerar uma nova Token? poderá fazer com que suas integrações parem",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    })
      .then((confirm) => {
        if (!confirm?.isConfirmed) return;

        Swal.fire({
          title: "Gerando Token",
          text: "Por favor, aguarde.",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        generateCredentialService("token").then(({ data }) => {
          Swal.close();
          setUser({
            ...user,
            token: data?.resultSet ?? null,
          });
        });
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGenerateApiKey = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja gerar uma nova ApiKey? poderá fazer com que suas integrações parem",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    })
      .then((confirm) => {
        if (!confirm?.isConfirmed) return;

        Swal.fire({
          title: "Gerando ApiKey",
          text: "Por favor, aguarde.",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        generateCredentialService("apikey").then(({ data }) => {
          Swal.close();
          setUser({
            ...user,
            apiKey: data?.resultSet ?? null,
          });
        })
          .catch((err) => {
            const code = err.response.data.code;
            const message = err.response.data.message;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `[${code}] ${message}`,
            });
          });
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    getUsers();
    getWebhook();
  }, []);

  if (!user.document) {
    return <></>;
  }

  return (
    <>
      {
        <div>
          <h5 className="mt-5 text-primary">
            Olá {user.name.split(" ")[0]}, bem vindo (a)!{" "}
          </h5>
          <Card className="mt-3 border-0 shadow">
            <CardHeader className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-lg-between align-items-start px-lg-5 py-4 px-3 bg-white">
              <div className="d-flex align-items-center">
                <h5
                  className="rounded-circle bg-primary text-white text-center m-0 d-flex align-items-center justify-content-center"
                  style={{ width: "50px", height: "50px" }}
                >
                  {`${user.name.slice(0, 2).toUpperCase()}`}
                </h5>

                <h5 className="m-0 ml-3">{user.name}</h5>
              </div>
            </CardHeader>
            <CardBody>
              <Nav style={{ height: '41px' }} tabs>
                <NavItem>
                  <NavLink
                    className={tabSelected === 1 && "active"}
                    onClick={() => {
                      setTabSelected(1);
                    }}
                  >
                    Dados Pessoais
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tabSelected === 2 && "active"}
                    onClick={() => {
                      setTabSelected(2);
                    }}
                  >
                    Credenciais de API
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tabSelected === 3 && "active"}
                    onClick={() => {
                      setTabSelected(3);
                    }}
                  >
                    Webhook
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={tabSelected}>
                <TabPane tabId={1}>
                  <Card>
                    <CardBody>
                      <Form className="d-flex p-0 flex-lg-row flex-column">
                        <Col className={`col-lg-6 p-lg-5 py-4 px-3 ${s.col}`}>
                          <h5 className="mb-4">Detalhes da Conta</h5>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Nome <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={editMode ? editedUser.name : user.name}
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    name: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                            <FormGroup className="col-xl-5 p-0 mt-xl-0 mt-3">
                              <Label>
                                Data de Nascimento <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                value={
                                  editMode
                                    ? editedUser.birthDate
                                    : user.birthDate
                                }
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    birthDate: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input value={user.email} disabled />
                            </FormGroup>
                            <FormGroup className="col-xl-5 col-12 p-0 mt-xl-0 mt-3">
                              <Label>
                                Telefone <span className="text-danger">*</span>
                              </Label>
                              <Input value={phoneMask(user.phone)} disabled />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Documento <span className="text-danger">*</span>
                              </Label>
                              <Input value={user.document} disabled />
                            </FormGroup>
                          </div>
                        </Col>
                        <Col className="col-lg-6 p-lg-5 py-4 px-3">
                          <h5 className="mb-4">Endereço</h5>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Cep <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={
                                  editMode ? editedUser.zipCode : user.zipCode
                                }
                                onChange={(e) =>
                                  handleGetZipCode(e.target.value)
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                            <FormGroup className="col-xl-5 col-12 p-0 mt-xl-0 mt-3">
                              <Label>
                                Rua <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={
                                  editMode ? editedUser.street : user.street
                                }
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    street: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Número <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={
                                  editMode
                                    ? editedUser.numberAddress
                                    : user.numberAddress
                                }
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    numberAddress: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                            <FormGroup className="col-xl-5 col-12 p-0 mt-xl-0 mt-3">
                              <Label>
                                Bairro <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={
                                  editMode ? editedUser.district : user.district
                                }
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    district: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>
                                Cidade <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={editMode ? editedUser.city : user.city}
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    city: e.target.value,
                                  })
                                }
                                disabled
                              />
                            </FormGroup>
                            <FormGroup className="col-xl-5 col-12 p-0 mt-xl-0 mt-3">
                              <Label>
                                Estado <span className="text-danger">*</span>
                              </Label>
                              <Input
                                value={editMode ? editedUser.state : user.state}
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    state: e.target.value,
                                  })
                                }
                                disabled
                              />
                            </FormGroup>
                          </div>
                          <div className="d-flex flex-xl-row flex-column justify-content-between mt-3">
                            <FormGroup className="col-xl-6 col-12 p-0">
                              <Label>Complemento</Label>
                              <Input
                                value={
                                  editMode
                                    ? editedUser.complement
                                    : user.complement
                                }
                                onChange={(e) =>
                                  setEditedUser({
                                    ...editedUser,
                                    complement: e.target.value,
                                  })
                                }
                                disabled={!editMode}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Form>
                    </CardBody>
                    <CardFooter className="border-0 bg-white px-lg-5 px-3 pb-4">
                      {!editMode && (
                        <Button
                          color="primary"
                          className="mt-lg-0 mt-4"
                          style={{ width: "120px" }}
                          onClick={() => handleEdit(user)}
                        >
                          Editar
                        </Button>

                      )}



                      {editMode && (
                        <div className="d-flex flex-lg-row flex-column">
                          <Button
                            color="primary"
                            className="mt-lg-0 mt-4"
                            style={{ width: "120px" }}
                            onClick={handleSave}
                          >
                            Salvar
                          </Button>
                          <Button
                            color="secondary"
                            className="mt-lg-0 mt-2 ml-lg-2"
                            style={{ width: "140px" }}
                            onClick={() => {
                              setEditMode(false);
                            }}
                          >
                            Cancelar
                          </Button>
                        </div>
                      )}
                    </CardFooter>
                  </Card>
                  <label> Campos com <span className="text-danger">* </span>são obrigatorios</label>
                </TabPane>
                <TabPane tabId={2}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>ApiKey</Label>
                            <InputGroup>
                              <Input type="text" value={user.apiKey} />
                              <InputGroupAddon addonType="append">
                                <Button onClick={handleGenerateApiKey}>
                                  Gerar novo
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label>Token</Label>
                            <InputGroup>
                              <Input type="text" value={user.token} />
                              <InputGroupAddon addonType="append">
                                <Button onClick={handleGenerateToken}>
                                  Gerar novo
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId={3}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <Label>Webhook</Label>
                            <div className="d-flex flex-lg-row flex-column align-items-lg-center align-items-start">
                              <InputGroup>
                                <Input
                                  type="text"
                                  value={webHook}
                                  onChange={(e) => setWebHook(e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    onClick={handleSaveWebHook}
                                  >
                                    Salvar
                                  </Button>

                                </InputGroupAddon>
                              </InputGroup>
                              {result && result.id > 0 && (
                                <Button
                                  color={result.active == 1 ? "danger" : "success"}
                                  className="mx-lg-2 mx-0 mt-lg-0 mt-2"
                                  onClick={() =>
                                    setHookStatus(
                                      result.id,
                                      Number(result.active) == 1 ? 0 : 1
                                    )
                                  }
                                >
                                  {Number(result.active) == 0
                                    ? "Ativar"
                                    : "Inativar"}
                                </Button>
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      }
    </>
  );
};

export default Profile;
