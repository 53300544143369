import api from "../api";

export const getAllClients = async (filter) => {
  return await api.get(`/api/v1/schedule/clients`, {
    params: filter,
  });
};

export const registerClient = async (data) => {
  return await api.post(`/api/v1/schedule/clients`, data);
};

export const clientStatus = async (clientId, newStatus) => {
  return await api.put(`/api/v1/schedule/status/${clientId}`, { newStatus });
};

export const updateClient = async (clientId, data) => {
  return await api.put(`/api/v1/schedule/clients/${clientId}`, data);
};

export const registerGroup = async (data) => {
  return await api.post(`/api/v1/schedule/groups`, data);
};

export const getAllGroups = async (param) => {
  return await api.get("/api/v1/schedule/groups", {
    params: param,
  });
};

export const getGroupNumbers = async (groupId) => {
  return await api.get(`/api/v1/schedule/groups/numbers/${groupId}`);
};

export const updateGroup = async (groupId, data) => {
  return await api.put(`/api/v1/schedule/groups/${groupId}`, data);
};

export const groupStatus = async (groupId, newStatus) => {
  return await api.put(`/api/v1/schedule/groups/status/${groupId}`, {
    newStatus,
  });
};

export const updateObservation = async (obsId, clientId, data) => {
  return await api.put(`/api/v1/schedule/observation/${obsId}`, {
    clientId,
    data,
  });
};

export const obsStatus = async (obsId, clientId, newStatus) => {
  return await api.put(`/api/v1/schedule/observation/status/${obsId}`, {
    clientId,
    newStatus,
  });
};

export const getObservationsById = async (clientId) => {
  return await api.get(`/api/v1/schedule/observation/${clientId}`);
};

export const getGroupsByClientId = async (clientId) => {
  return await api.get(`/api/v1/chat/groups/${clientId}`);
};

export const getGroupsContacts = async () => {
  return await api.get(`/api/v1/schedule/group-contacts`);
};