import React, { useEffect, useState, useRef } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Row,
  Tooltip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import debounce from "lodash/debounce";
import {
  Avatar,
  HistoryDescWrapper,
  HistoryItem,
  HistoryWrapper,
  PersonWrapper,
} from "../styles";
import "./NavTab.css";

import { phoneMask } from "utils/mask";
import { FormatMessageWhatsApp } from "utils/FormatMessageWhatsApp";
import { listAllInstanceService } from "services/Instance";

import "./NavTab.css"

export const ChatContactComponent = ({
  contacts,
  className = "col-12 col-lg-5 col-xl-4 d-flex align-content-stretch",
  handleGetMessagesClient = () => { },
  contactSelected = null,
  handleSelectItem = () => { },
  handleListContact = (filter) => { },
  toggleModal = () => { },
  handleCloseConversation = () => { },
  setSelectedInstanceId = () => { },
  getCountChats = () => { },
  selectedInstanceId = null,
  filter = "",
  setFilter = () => { },
  showBtnMoreContacts,
  isLoading,
  setIsLoading = () => { },
  limit = 0,
  previousLimit = 60,
  setPreviousLimit = () => { },
  tabNavSelected,
  setTabNavSelected,
  numberChatService
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [tooltipGroupOpen, setTooltipGroupOpen] = useState([]);
  const [searchTooltipOpen, setSearchTooltipOpen] = useState(false);
  const [visibleBadgeCount, setVisibleBadgeCount] = useState(0);
  const containerRef = useRef(null);
  const [instances, setInstances] = useState([]);
  const [instanceId, setInstanceId] = useState(null);
  const [instanceButtonSelected, setInstanceButtonSelected] = useState(false);

  const handleLoadMore = () => {
    const limitAux = previousLimit + 30;
    setPreviousLimit(limitAux);

    handleListContact({
      filter: filter,
      instanceId: selectedInstanceId,
      limit: limit,
      previousLimit: limitAux,
    });
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleSearchTooltip = () => {
    setSearchTooltipOpen(!searchTooltipOpen);
  };
  const toggleTooltip = (index) => {
    setTooltipOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });
  };

  const handleTypeMessage = (message) => {
    if (message.type === "imageMessage") {
      return "IMAGEM";
    }

    if (message.type === "audioMessage") {
      return "AUDIO";
    }

    if (message.type === "videoMessage") {
      return "VIDEO";
    }

    if (!message?.message) return "";

    return FormatMessageWhatsApp(message.message);
  };

  const updateVisibleBadgeCount = () => {
    if (window.innerWidth <= 325) {
      setVisibleBadgeCount(0);
      return;
    }

    if (window.innerWidth <= 470) {
      setVisibleBadgeCount(1);
      return;
    }

    if (window.innerWidth <= 854) {
      setVisibleBadgeCount(3);
      return;
    }

    if (window.innerWidth <= 991) {
      setVisibleBadgeCount(5);
      return;
    }

    if (window.innerWidth <= 1407) {
      setVisibleBadgeCount(0);
      return;
    }

    if (window.innerWidth <= 1880) {
      setVisibleBadgeCount(1);
      return;
    }

    if (window.innerWidth >= 1880) {
      setVisibleBadgeCount(2);
      return;
    }

    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const badgeElements = Array.from(
        containerRef.current.querySelectorAll(".badge")
      );
      let totalWidth = 0;
      let visibleCount = 0;

      badgeElements.forEach((badgeElement) => {
        const badgeWidth =
          badgeElement.offsetWidth +
          parseInt(window.getComputedStyle(badgeElement).marginRight, 10);
        totalWidth += badgeWidth;
      });

      let accumulatedWidth = 0;
      for (let i = 0; i < badgeElements.length; i++) {
        const badgeElement = badgeElements[i];
        const badgeWidth =
          badgeElement.offsetWidth +
          parseInt(window.getComputedStyle(badgeElement).marginRight, 10);
        accumulatedWidth += badgeWidth;

        if (accumulatedWidth <= containerWidth) {
          visibleCount = i + 1;
        } else {
          break;
        }
      }
      setVisibleBadgeCount(Math.max(visibleCount, 0));
    }
  };

  const debouncedUpdateVisibleBadgeCount = debounce(
    updateVisibleBadgeCount,
    100
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateVisibleBadgeCount();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      debouncedUpdateVisibleBadgeCount.cancel();
    };
  }, [contacts]);

  useEffect(() => {
    const handleResize = () => {
      updateVisibleBadgeCount();
    };

    window.addEventListener("resize", handleResize);
    updateVisibleBadgeCount();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updateVisibleBadgeCount();
    setPreviousLimit(60);
  }, []);

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const response = await listAllInstanceService();
        setInstances(response.data.resultSet);
      } catch (error) {
        console.error("Erro ao buscar instâncias:", error);
      }
    };
    fetchInstances();
  }, []);

  const handleAllClick = () => {
    setSelectedInstanceId(null);
    setInstanceId(null);
    setInstanceButtonSelected(false);
    handleListContact({
      filter: filter,
      limit: limit,
      previousLimit: previousLimit,
    });
    handleCloseConversation();
  };

  const handleToggleTooltip = (id) => {
    setTooltipGroupOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      [id]: !prevTooltipOpen[id],
    }));
  };

  return (
    <div className={className}>
      <Card className="pt-4 pb-4 w-100">
        <Container className="p-0">
          <div className="d-flex align-items-center px-3 justify-content-between position-relative">
            <h3 className="text-success">Contatos</h3>
            <Dropdown
              isOpen={showDropdown}
              toggle={() => handleToggleDropdown()}
              className="position-relative"
            >
              <DropdownToggle
                style={{ width: "50px", height: "50px" }}
                color="light"
                className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center"
              >
                <i className="fa fa-ellipsis-v text-success" />
              </DropdownToggle>
              <DropdownMenu style={{ margin: "5px 7rem 0 0" }}>
                <DropdownItem onClick={toggleModal}>Nova Mensagem</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <FormGroup className="d-flex px-3 mt-3">
            <InputGroup>
              <input
                id="phone"
                type="text"
                placeholder="Pesquisar..."
                className="form-control form-control-md"
                value={filter}
                onChange={(ev) => setFilter(ev.target.value)}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    handleListContact({
                      filter: filter,
                      instanceId: selectedInstanceId,
                      limit: limit,
                      previousLimit: previousLimit,
                    });
                  }
                }}
                title="Digite o número, nome ou mensagem do contato para realizar a pesquisa."
              />
              <Tooltip
                placement="top"
                isOpen={searchTooltipOpen}
                target="phone"
                toggle={toggleSearchTooltip}
                style={{
                  backgroundColor: "#FFF",
                  border: "1px solid #C7D0D9",
                  color: "#000",
                  fontWeight: "600",
                  borderRadius: "5px",
                  width: "15rem",
                  padding: "10px",
                  whiteSpace: "pre-wrap",
                  textAlign: "left",
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                Digite o número, nome ou mensagem do contato para realizar a
                pesquisa.
              </Tooltip>
              <InputGroupAddon addonType="append">
                <Button
                  className="py-0 px-2 bg-white border-left-0"
                  onClick={() =>
                    handleListContact({
                      filter: filter,
                      instanceId: selectedInstanceId,
                      limit: limit,
                      previousLimit: previousLimit,
                    })
                  }
                >
                  <i className="fa fa-search text-secondary"></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          {instances.length > 1 && (
            <>
              <div className="bg-light m-3 p-3 rounded">
                {instances.map((instance) => {
                  if (
                    instanceButtonSelected &&
                    instance.id !== selectedInstanceId
                  ) {
                    return null;
                  }
                  return (
                    <Badge
                      key={instance.id}
                      className={`mr-2 p-2 mt-2 ${instance.id === selectedInstanceId
                        ? "bg-secondary text-dark"
                        : "bg-success text-white"
                        }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedInstanceId(instance.id);
                        handleListContact({
                          filter: filter,
                          instanceId: instance.id,
                          limit: limit,
                          previousLimit: previousLimit,
                        });
                        getCountChats(instance.id)
                        setInstanceButtonSelected(true);
                        handleCloseConversation();
                      }}
                    >
                      {instance.name}
                    </Badge>
                  );
                })}
                {instanceButtonSelected && (
                  <Badge
                    onClick={() => {
                      handleAllClick();
                      getCountChats();
                    }}
                    className="mr-2 p-2 bg-success text-white"
                    style={{ cursor: "pointer" }}
                  >
                    Todas
                  </Badge>
                )}
              </div>
            </>
          )}
          <Nav className="navTab" tabs>
            <NavItem className="w-50">
              <NavLink
                className={tabNavSelected === 1 && "active"}
                onClick={() => {
                  setTabNavSelected(1);
                }}
              >
                <i
                  className="eva eva-message-circle-outline"
                  style={{
                    width: "35px",
                    marginRight: "6px",
                    verticalAlign: "bottom",
                  }}
                />
                <span style={{ verticalAlign: "baseline" }}>
                  Em Atendimento
                </span>
                <Badge color="success" className="badgeChat">{numberChatService}</Badge>
              </NavLink>
            </NavItem>
            <NavItem className="w-50">
              <NavLink
                className={tabNavSelected === 2 && "active"}
                onClick={() => {
                  setTabNavSelected(2);
                }}
              >
                <i
                  className="eva eva-inbox-outline"
                  style={{
                    width: "35px",
                    marginRight: "6px",
                    verticalAlign: "bottom",
                  }}
                />
                <span style={{ verticalAlign: "baseline" }}>Fila</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabNavSelected}>
            <TabPane tabId={1}>
              {contacts.length > 0 ? (
                <>
                  <HistoryWrapper>
                    {contacts.map(
                      (contact, index) =>
                        contact.userIdChat > 0 && (
                          <HistoryItem
                            key={index}
                            onClick={() => {
                              handleGetMessagesClient(
                                contact.clientPhoneNumberId,
                                selectedInstanceId,
                                contact
                              );
                              handleSelectItem(contact.clientPhoneNumberId);
                            }}
                            style={{
                              backgroundColor:
                                contactSelected === contact.clientPhoneNumberId
                                  ? "#DCDCDC90"
                                  : "inherit",
                            }}
                          >
                            <div>
                              <Avatar>{contact.name?.slice(0, 2)}</Avatar>
                            </div>
                            <HistoryDescWrapper>
                              <PersonWrapper>
                                <Row className="d-flex justify-content-between w-100 p-0">
                                  <Col sm="7">
                                    <div className="d-flex flex-column">
                                      <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                        <p className="username">
                                          {contact.name}
                                        </p>
                                        <div
                                          className="d-flex ml-lg-3 align-items-center my-md-0 my-1"
                                          style={{
                                            gap: "5px",
                                            position: "relative",
                                          }}
                                          ref={containerRef}
                                        >
                                          {Array.isArray(contact.groups) &&
                                            contact.groups.length > 0 && (
                                              <>
                                                {contact.groups
                                                  .slice(0, visibleBadgeCount)
                                                  .map((group, idx) => {
                                                    const badgeId = `badge-${contact.clientPhoneNumberId}-${idx}`;
                                                    const tooltipId = `tooltip-${contact.clientPhoneNumberId}-${idx}`;

                                                    return (
                                                      <React.Fragment
                                                        key={badgeId}
                                                      >
                                                        <Badge
                                                          id={badgeId}
                                                          color="primary"
                                                          className="badge-group"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleToggleTooltip(
                                                              tooltipId
                                                            )
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "11px",
                                                              maxWidth: "100px",
                                                              lineHeight:
                                                                "11px",
                                                              textOverflow:
                                                                "ellipsis",
                                                              whiteSpace:
                                                                "nowrap",
                                                              overflow:
                                                                "hidden",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            {group.name}
                                                          </span>
                                                        </Badge>
                                                        <Tooltip
                                                          id={tooltipId}
                                                          placement="top"
                                                          isOpen={
                                                            tooltipGroupOpen[
                                                            tooltipId
                                                            ]
                                                          }
                                                          target={badgeId}
                                                          toggle={() =>
                                                            handleToggleTooltip(
                                                              tooltipId
                                                            )
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "#FFF",
                                                            border:
                                                              "1px solid #C7D0D9",
                                                            color: "#000",
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {group.name}
                                                        </Tooltip>
                                                      </React.Fragment>
                                                    );
                                                  })}

                                                {contact.groups.length >
                                                  visibleBadgeCount && (
                                                    <UncontrolledDropdown
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <DropdownToggle
                                                        color="light"
                                                        id="dropdown-basic"
                                                        className="d-flex align-items-center justify-content-center badge bg-secondary mb-lg-0 mb-1"
                                                        style={{
                                                          fontSize: "11px",
                                                          maxHeight: "20px",
                                                          cursor: "pointer",
                                                          width: "auto",
                                                          padding: "11px 15px",
                                                        }}
                                                      >
                                                        +
                                                        {contact.groups.length -
                                                          visibleBadgeCount}
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        {contact.groups
                                                          .slice(
                                                            visibleBadgeCount
                                                          )
                                                          .map((group) => (
                                                            <DropdownItem
                                                              key={group.id}
                                                              className="p-2"
                                                            >
                                                              {group.name}
                                                            </DropdownItem>
                                                          ))}
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                  )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                      <p className="phone">
                                        {phoneMask(contact.phone)}
                                      </p>
                                      {contact.attendentName && (
                                        <p
                                          className="text-primary"
                                          style={{ fontSize: "13px" }}
                                        >
                                          <i className="fas fa-headset"></i>{" "}
                                          {contact.attendentName}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <div className="text-sm-right p-sm-0 mt-sm-0 mt-2 pl-md-0 pl-3">
                                    <p className="date">{contact.createdAt}</p>
                                  <div>
                                    <Badge color="info">
                                      {contact.instanceName}
                                    </Badge> 
                                          {contact.isRead > 0 ? ( 
                                            <Badge color="success ml-1">
                                              <i className="fa-solid fa-face-laugh-beam"></i>
                                            </Badge>
                                          ) : (
                                            <Badge color="warning ml-1">
                                              <i className="fa-solid fa-hourglass-start"></i>
                                            </Badge>
                                          )}
                                      </div>
                                  </div>
                                  <Col className="last-message w-100 col-12">
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipOpen[index]}
                                      target={`tooltip-${index}`}
                                      toggle={() => toggleTooltip(index)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px",
                                      }}
                                    >
                                      <p>{handleTypeMessage(contact)}</p>
                                    </Tooltip>
                                    <div id={`tooltip-${index}`}>
                                      <p
                                        className={`message ${contact.fromMe <= 0 &&
                                          "font-weight-bold"
                                          }`}
                                      >
                                        {contact.fromMe === "1" ? (
                                          <i className="fa-solid fa-paper-plane text-info mr-1"></i>
                                        ) : (
                                          <i className="fa-solid fa-envelope text-success mr-1"></i>
                                        )}
                                        {handleTypeMessage(contact)}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </PersonWrapper>
                            </HistoryDescWrapper>
                          </HistoryItem>
                        )
                    )}
                  </HistoryWrapper>
                </>
              ) : (
                <p className="text-success pt-2 pl-4">Não possuem registros</p>
              )}
            </TabPane>
            <TabPane tabId={2}>
              {contacts.length > 0 ? (
                <>
                  <HistoryWrapper>
                    {contacts.map(
                      (contact, index) =>
                        contact.userIdChat <= 0 && (
                          <HistoryItem
                            key={index}
                            onClick={() => {
                              handleGetMessagesClient(
                                contact.clientPhoneNumberId,
                                selectedInstanceId,
                                contact
                              );
                              handleSelectItem(contact.clientPhoneNumberId);
                            }}
                            style={{
                              backgroundColor:
                                contactSelected === contact.clientPhoneNumberId
                                  ? "#DCDCDC90"
                                  : "inherit",
                            }}
                          >
                            <div>
                              <Avatar>{contact.name?.slice(0, 2)}</Avatar>
                            </div>
                            <HistoryDescWrapper>
                              <PersonWrapper>
                                <Row className="d-flex justify-content-between w-100 p-0">
                                  <Col sm="7">
                                    <div className="d-flex flex-column">
                                      <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                        <p className="username">
                                          {contact.name}
                                        </p>
                                        <div
                                          className="d-flex ml-lg-3 align-items-center my-md-0 my-1"
                                          style={{
                                            gap: "5px",
                                            position: "relative",
                                          }}
                                          ref={containerRef}
                                        >
                                          {Array.isArray(contact.groups) &&
                                            contact.groups.length > 0 && (
                                              <>
                                                {contact.groups
                                                  .slice(0, visibleBadgeCount)
                                                  .map((group, idx) => {
                                                    const badgeId = `badge-${contact.clientPhoneNumberId}-${idx}`;
                                                    const tooltipId = `tooltip-${contact.clientPhoneNumberId}-${idx}`;

                                                    return (
                                                      <React.Fragment
                                                        key={badgeId}
                                                      >
                                                        <Badge
                                                          id={badgeId}
                                                          color="primary"
                                                          className="badge-group"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleToggleTooltip(
                                                              tooltipId
                                                            )
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "11px",
                                                              maxWidth: "100px",
                                                              lineHeight:
                                                                "11px",
                                                              textOverflow:
                                                                "ellipsis",
                                                              whiteSpace:
                                                                "nowrap",
                                                              overflow:
                                                                "hidden",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            {group.name}
                                                          </span>
                                                        </Badge>
                                                        <Tooltip
                                                          id={tooltipId}
                                                          placement="top"
                                                          isOpen={
                                                            tooltipGroupOpen[
                                                            tooltipId
                                                            ]
                                                          }
                                                          target={badgeId}
                                                          toggle={() =>
                                                            handleToggleTooltip(
                                                              tooltipId
                                                            )
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "#FFF",
                                                            border:
                                                              "1px solid #C7D0D9",
                                                            color: "#000",
                                                            borderRadius: "5px",
                                                            padding: "10px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {group.name}
                                                        </Tooltip>
                                                      </React.Fragment>
                                                    );
                                                  })}

                                                {contact.groups.length >
                                                  visibleBadgeCount && (
                                                    <UncontrolledDropdown
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <DropdownToggle
                                                        color="light"
                                                        id="dropdown-basic"
                                                        className="d-flex align-items-center justify-content-center badge bg-secondary mb-lg-0 mb-1"
                                                        style={{
                                                          fontSize: "11px",
                                                          maxHeight: "20px",
                                                          cursor: "pointer",
                                                          width: "auto",
                                                          padding: "11px 15px",
                                                        }}
                                                      >
                                                        +
                                                        {contact.groups.length -
                                                          visibleBadgeCount}
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        {contact.groups
                                                          .slice(
                                                            visibleBadgeCount
                                                          )
                                                          .map((group) => (
                                                            <DropdownItem
                                                              key={group.id}
                                                              className="p-2"
                                                            >
                                                              {group.name}
                                                            </DropdownItem>
                                                          ))}
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                  )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                      <p className="phone">
                                        {phoneMask(contact.phone)}
                                      </p>
                                    </div>
                                  </Col>
                                  <div className="text-sm-right p-sm-0 mt-sm-0 mt-2 pl-md-0 pl-3">
                                    <p className="date">{contact.createdAt}</p>
                                  <div>
                                    <Badge color="info">
                                      {contact.instanceName}
                                    </Badge>
                                          {contact.isRead > 0 ? ( 
                                            <Badge color="success ml-1">
                                              <i className="fa-solid fa-face-laugh-beam"></i>
                                            </Badge>
                                          ) : (
                                            <Badge color="warning ml-1">
                                              <i className="fa-solid fa-hourglass-start"></i>
                                            </Badge>
                                          )}
                                      </div>
                                  </div>
                                  <Col className="last-message w-100 col-12">
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipOpen[index]}
                                      target={`tooltip-${index}`}
                                      toggle={() => toggleTooltip(index)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px",
                                      }}
                                    >
                                      <p>{handleTypeMessage(contact)}</p>
                                    </Tooltip>
                                    <div id={`tooltip-${index}`}>
                                      <p
                                        className={`message ${contact.fromMe <= 0 &&
                                          "font-weight-bold"
                                          }`}
                                      >
                                        {contact.fromMe === "1" ? (
                                          <i className="fa-solid fa-paper-plane text-info mr-1"></i>
                                        ) : (
                                          <i className="fa-solid fa-envelope text-success mr-1"></i>
                                        )}
                                        {handleTypeMessage(contact)}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </PersonWrapper>
                            </HistoryDescWrapper>
                          </HistoryItem>
                        )
                    )}
                    {showBtnMoreContacts &&
                      (isLoading ? (
                        <Alert className="d-flex w-100 m-0 rounded-0 bg-success align-items-center justify-content-center">
                          <i className="fas fa-spinner fa-spin" />
                          <strong
                            className="mb-0"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            Carregando Contatos...
                          </strong>
                        </Alert>
                      ) : (
                        <Button
                          className="w-100 rounded-0 btn-success"
                          onClick={() => {
                            setIsLoading(true);
                            handleLoadMore();
                          }}
                        >
                          Carregar mais...
                        </Button>
                      ))}
                  </HistoryWrapper>
                </>
              ) : (
                <p className="text-success pt-2 pl-4">Não possuem registros</p>
              )}
            </TabPane>
          </TabContent>
        </Container>
      </Card>
    </div>
  );
};
